import { toast } from "react-toastify";
import { errorToast } from "../modules/common/components/toast";

export const validationError = (res?: any) => {
  //   const errorObject = res?.response?.data?.data;
  //   Object.keys(errorObject).map((key) => {
  //     const messages = errorObject[key].join(" ");
  //     return toast(`${key}: ${messages}`, errorToast);
  //   });
  return toast("Invalid serial number. Please check and try again.", errorToast);
};
